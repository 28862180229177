import Header2 from "./Header2";
import Footer from "./Footer";
import {RenderElement} from "./common";
import { useEffect } from 'react'

function DeleteData() {
    
    useEffect(() => {
        document.title = "Juleo | Delete Account"; // Set the title dynamically
      }, []); 

    return (
        <div className="flex-col h-screen">
            <Header2 />
            <div className="px-4 flex-col items-center justify-center md:justify-normal md:px-[120px] space-y-2 text-sm">
                <div className="flex-col font-Frank space-y-4 mt-4">
                    <p className="font-bold">To request the deletion of your Juleo account, please follow these steps:</p>
                </div>
                
                <div className="space-y-2">
                    <RenderElement left={<p className="font-bold">1.</p>} right={
                        <div className="space-y-2">
                            <div className="space-y-2">
                                <p className=""><span className="font-bold">Verify Your Identity: </span>Send an email to genie@juleo.club from the email address associated with your account.</p>
                            </div>
                        </div>
                    } />
                    <RenderElement left={<p className="font-bold">2.</p>} right={
                        <div className="space-y-2">
                            <div className="space-y-2">
                                <p className=""><span className="font-bold">Confirmation: </span>You'll receive a confirmation call to your registered phone number. To protect your account, we'll ask you to verify some information. Once your identity is confirmed, we'll proceed with deleting your account as outlined in our privacy policy.</p>
                            </div>
                        </div>
                    } />
                    <RenderElement left={<p className="font-bold">3.</p>} right={
                        <div className="space-y-2">
                            <div className="space-y-2">
                                <p className="">Below are highlighted sections from our privacy policy regarding data deletion. For more details, visit our complete privacy policy <span className="text-blue-700"><a href="/privacy" target="_blank">here</a> </span>.</p>
                            </div>
                        </div>
                    } />
                    <br/>
                    <div>
                    <RenderElement left={<p className="font-bold">1.</p>} right={
                        <div className="space-y-2">
                            <div className="space-y-2">
                            <p><span className="font-bold">Paragraph 6: </span>Your Rights and Choices </p>
                                <RenderElement left={"6(e)"} right={<p>You may request that we delete the personal information we keep about you. You can exercise your right to delete by submitting a request on the Platform or contacting us anytime at genie@juleo.club.</p>} />
                            </div>
                        </div>
                    } />
                    <br/>
                    <RenderElement left={<p className="font-bold">2.</p>} right={
                    <div className="space-y-2">
                        <div className="space-y-2">
                            <p><b>Paragraph 8</b>: Data Retention and Deletion</p>
                        </div>
                        <div className="">
                            <RenderElement left={"(a)"} right={<p>We will retain your information only for as long as it is necessary for providing you with the services available on the Platform, to comply with legal obligations, or until you request termination of your account with Us, whichever is later. However, we do not retain any of your information beyond 5 years, except where required by law.

                            </p>
                            } />
                        </div>
                        <div className="">
                            <RenderElement left={"(b)"} right={<p>Upon termination of your account, we will remove your profile from public visibility and restrict further processing of your data. However, for operational, security/fraud detection and legal purposes, we will retain the following user records in our secure databases on Amazon, Google Clouds:

                                <div className="my-2">
                                    <RenderElement left={"•"} right={<p><b>Government / Identity Records:</b>&nbsp;user name, phone_no, id_used, date_of_birth, fathers_name, address
                                    </p>} />

                                    <RenderElement left={"•"} right={<p><b>Demographic Information:</b>&nbsp;city, income, gender
                                    </p>} />

                                    <RenderElement left={"•"} right={<p><b>Media & Verification Data:</b>&nbsp;selfie_image (stored securely on Amazon Cloud servers for verification and fraud prevention); profile images retained for algorithm training and service improvements, right after which they will be permanently deleted
                                    </p>} />

                                    <RenderElement left={"•"} right={<p><b>Usage & Platform Security Data:</b>&nbsp;match details (anonymized)
                                    </p>} />

                                </div>

                            </p>} />
                        </div>
                        <div className="">
                            <RenderElement left={"(c)"} right={<p>Post-termination of your account, we may continue to use your anonymised data aggregated or combined with other Users' anonymised data</p>} />
                        </div>
                        <div className="">
                            <RenderElement left={"(d)"} right={<p>In cases where a User has violated the Terms and Conditions of Our Platform or submits a request for deletion of their personal data, we will promptly take necessary actions in accordance with Paragraph 6(e). However, Juleo reserves the right to retain information required for compliance with applicable laws, fraud detection, or enforcement of our policies.</p>} />
                        </div>

                        <div className="">
                            <RenderElement left={"(e)"} right={<p>If you wish to withdraw your consent for processing your information, you may submit a request at genie@juleo.club. However, we will continue to retain information which is required to be retained under the applicable laws.</p>} />
                        </div>
                    </div>
                } />
                    </div>
                </div>
            </div>
            <div className="mt-auto">
            <Footer />
            </div>
        </div>
    );
}



export default DeleteData;
